<template>
  <div class="rounded-lg bg-white">
    <transition
      enter-active-class="miniFadeInUp"
      leave-active-class="miniFadeOutDown"
      mode="out-in"
    >
      <div v-if="step.catDetail" key="catDetail" class="flex w-full">
        <div class="m-2 w-full">
          <cat-card
            :key="'cat-' + catIndex"
            :position="catIndex"
            :cat="catTemplate"
            :selectedCat="catIndex"
            isFull="true"
            :not-taster="true"
          />
          <div class="m-2">
            <form
              @submit.prevent="getFood"
              class="mt-4 block w-full text-center"
            >
              <loading-button
                :is-loading="isLoading"
                :is-enabled="true"
                :is-full="true"
                class="block"
              >
                <div class="w-full text-center">Choose food</div>
              </loading-button>
            </form>
          </div>
        </div>
      </div>
      <div v-if="step.food" key="food">
        <!-- <cat-subscription
          :position="data.items.length"
          :cat="cat"
          :plan="currentPlans.items[0]"
          :isAdjustable="showAdjust"
          @has-updated="getPrice"
          :isLoading="isLoading"
          class="m-4"
        /> -->
        <div
          class="large-modal relative mx-auto flex w-full flex-col px-4 pt-8 sm:px-2"
        >
          <product-selection
            hide-detail
            :cat="catTemplate"
            :products="products"
            :catIndex="catIndex"
            :is-adjust="true"
            @getSubscriptionCost="getSubscriptionCost"
            @totalsUpdated="setTotals"
            :is-full="true"
            is-add
          />

          <div class="sticky bottom-0 z-20 border-t-2">
            <transition
              enter-active-class="miniFadeInTop"
              leave-active-class="miniFadeOutBottom"
              mode="out-in"
            >
              <div
                v-if="subValidation.isWetMax"
                class="absolute left-0 right-0 top-0 text-sm text-indigo-200 shadow-lg"
                :class="{
                  '-mt-16 sm:-mt-12': subValidation.isWetMax,
                  '-mt-12 sm:-mt-12': !isMultiples
                }"
              >
                <div
                  class="flex w-full flex-col items-center justify-center space-x-2 rounded bg-indigo-500 py-2 text-center sm:flex-row"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <font-awesome-icon
                      class="text-base text-white"
                      :icon="['far', 'siren-on']"
                    />
                    <span
                      class="mr-1 font-semibold text-white"
                      v-if="subValidation.isWetMax"
                      >Maximum reached</span
                    >
                    <span
                      class="mr-1 font-semibold text-white"
                      v-if="!isMultiples"
                    >
                      Add
                      <span class="font-semibold text-white">{{
                        needToAdd
                      }}</span>
                      more pouches / trays to continue</span
                    >
                  </div>
                  <div v-if="subValidation.isWetMax">
                    {{ this.$store.state.core.maxWet }} pouches / trays per cat.

                    <a class="underline" href="mailto:team@thecoolcatclub.com"
                      >Need more?</a
                    >
                  </div>
                </div>
              </div>
            </transition>
            <div
              class="-ml-0 -mr-0 flex flex-col items-center rounded-lg bg-white p-4 px-4 pb-4 font-merri font-semibold backdrop-blur"
            >
              <div class="mb-2 w-full items-center">
                <div class="default-transition mr-4 hidden w-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 62.55 64.55"
                    class="text-teal-400"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          class="avatar-bg"
                          d="M59.53,52.21c1.95-2.87,7.2-42.69-3.47-48.55C50.8.77,34.39,14.06,34.39,14.06S18.79-1.93,12.72.19C-1.47,5.14-4.21,49.62,6.65,60,16.31,69.24,52,63.25,59.53,52.21Z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="hidden font-sans">
                  <div>
                    Choosing food for
                    {{ catTemplate.name }}
                  </div>
                  <div
                    class="font-sans text-sm font-normal italic text-gray-600"
                  >
                    Adjust or cancel anytime!
                  </div>
                </div>
              </div>

              <div
                class="flex w-full flex-grow flex-col items-center justify-between space-y-4 text-right font-sans"
              >
                <div class="w-full flex-grow">
                  <amount-tracker :amounts="totalAmounts">
                    <div class="disabled-popup flex-none">
                      <button
                        class="btn ml-4"
                        @click="getPrice()"
                        :disabled="!subValidation.isValid || !isMultiples"
                        :class="{
                          'pointer-events-none opacity-50':
                            !subValidation.isValid || !isMultiples
                        }"
                      >
                        Next
                        <font-awesome-icon
                          :icon="['far', 'arrow-right-long']"
                          class="ml-2"
                        />
                      </button></div
                  ></amount-tracker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="step.price" key="price" class="grid w-full grid-cols-1 p-4">
        <div
          class="col-span-4 my-16 mb-8 mt-0 mt-8 w-full px-0 text-center font-merri text-xl sm:px-4"
        >
          Add cat to subscription
        </div>

        <div
          class="cat-card flex w-full flex-col overflow-hidden rounded-lg border-2"
        >
          <div class="divide-y-2 divide-dashed">
            <cat-subscription-mini
              :cat="lastCat"
              :plan="lastPlan"
              :has-actions="false"
              :isActive="true"
            />
          </div>
        </div>
        <div class="col-span-4 pt-8 text-center">
          <!-- <div class="text-lg w-full mt-8 sm:mb-0">Total for cat</div> -->
          <!-- <div
            class="text-5xl text-center px-2 font-semibold  inline-block mb-4"
          >
            {{ catCost.total }}
          </div> -->
          <form @submit.prevent="updateSubscription">
            <loading-button
              :is-loading="isLoading"
              :is-enabled="true"
              :secondary="true"
              class="w-full"
              is-full
            >
              <span>Add cat</span>

              <font-awesome-icon
                :icon="['far', 'arrow-right-long']"
                class="ml-2"
              />
            </loading-button>
          </form>
        </div>
      </div>
    </transition>
    <div ref="validationMessage">
      <div v-html="subValidation.message"></div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import CatSubscriptionMini from '@/components/CatSubscriptionMini';
import CatCard from '@/components/CatCard';
import LoadingButton from '@/components/LoadingButton';
import AmountTracker from '@/components/AmountTracker';
import ProductSelection from '@/components/products/ProductSelection';
import utils from '@/utils';
import tippy from 'tippy.js';
import { mapGetters } from 'vuex';
import { isEmpty, clone, last } from 'lodash/fp';

export default {
  props: ['data'],
  components: {
    LoadingButton,
    CatCard,
    AmountTracker,
    ProductSelection,
    CatSubscriptionMini
  },
  data() {
    return {
      catTemplate: {
        name: '',
        gender: '',
        tribe: '',
        isKitten: false,
        active: true,
        sampleboxtype: {
          dryfood: true,
          type: 'both'
        },
        products: [],
        foodAmount: 60
      },
      subscriptionCost: null,
      isLoading: false,

      step: {
        catDetail: true,
        food: false,
        price: false
      },
      currentPlans: null,
      showAdjust: false,
      validationTippy: [],
      totalAmounts: null
    };
  },
  computed: {
    ...mapGetters({
      products: 'productGroupsGetter',
      subValidation: 'subValidation',
      subscription: 'getCurrentSubscription'
    }),
    color() {
      let color = utils.getCatColor(this.data.items.length);
      return color;
    },
    catIndex() {
      return this.data.items.length;
    },
    isMultiples() {
      if (this.totalAmounts) {
        const totalWet =
          this.totalAmounts['wet_pouch'] +
          this.totalAmounts['wet_tray'] +
          this.totalAmounts['wet_can'] +
          this.totalAmounts['wet_smooth'] +
          this.totalAmounts['wet_tasty'] +
          this.totalAmounts['wet_chunk'];
        const numbers = [totalWet];

        const multiplesOf = numbers.filter((n) => !(n % 15));
        return !isEmpty(multiplesOf);
      }

      return this.totalAmounts;
    },
    needToAdd() {
      if (this.totalAmounts) {
        const totalWet =
          this.totalAmounts['wet_pouch'] +
          this.totalAmounts['wet_tray'] +
          this.totalAmounts['wet_can'] +
          this.totalAmounts['wet_smooth'] +
          this.totalAmounts['wet_tasty'] +
          this.totalAmounts['wet_chunk'];
        return Math.ceil(totalWet / 15) * 15 - totalWet;
      }
      return 0;
    },
    cats() {
      const cats = clone(this.data.items);

      const costing = this.catCost ? { displayCost: this.catCost.total } : {};

      const newTemplate = { ...this.catTemplate, ...costing };

      cats.push(newTemplate);
      return cats;
    },
    catCost() {
      return this.subscriptionCost ? last(this.subscriptionCost.cats) : false;
    },
    lastPlan() {
      return this.subscriptionCost ? last(this.subscriptionCost.cats) : false;
    },
    lastCat() {
      return last(this.cats);
    }
  },
  watch: {
    subValidation(value) {
      this.initTippy().then(() => {
        if (!value.isValid) {
          this.validationTippy[0].enable();
        } else {
          this.validationTippy[0].disable();
        }
      });
    }
  },

  methods: {
    setTotals(payload) {
      this.totalAmounts = payload;
    },
    closeModal: function () {
      this.$parent.$emit('make-close');
    },

    initTippy: function () {
      return new Promise((resolve) => {
        if (!this.validationTippy.length > 0) {
          this.validationTippy = tippy('.disabled-popup', {
            allowHTML: true,
            animation: 'shift-away',
            maxWidth: 200,
            content: this.$refs.validationMessage
          });
        }
        resolve();
      });
    },
    updateSubscription: function () {
      this.isLoading = true;
      // delete cat.wetFood;
      // delete cat.dryFood;
      // const catsList = _.clone(this.data.items);
      // catsList.push(cat);
      const data = this.cats;

      data.forEach((d) => {
        d.type = d.isKitten ? 'kitten' : 'adult';
        delete d.foodAmount;
        delete d.isKitten;
      });

      const payload = {
        items: data
      };

      // for each of the items products. remove getPrice if its present
      payload.items.forEach((item) => {
        item.products.forEach((product) => {
          delete product.getPrice;
          delete product.group;
        });
      });

      if (this.subscription?.active) {
        payload.delivery = this.subscription.deliveryTypeUserSelected;
      }
      http
        .put(this.data.links.adjust, payload)
        .then(() => {
          this.isLoading = false;

          this.$store.dispatch('getSubscription');
          this.closeModal();
        })
        .catch((err) => {
          this.isLoading = false;
          throw Error(err.response.data.error.message);
        });
    },
    getSubscriptionCost: function () {
      this.isLoading = true;
      this.$store
        .dispatch('getSubscriptionCost', this.cats)
        .then((response) => {
          this.isLoading = false;
          this.subscriptionCost = response;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getFood: function () {
      this.catTemplate.products = utils.foodSuggestor(this.catTemplate);
      this.getSubscriptionCost();
      if (!this.catTemplate.name) {
        this.catTemplate.name = 'Cat ' + (this.data.items.length + 1);
      }
      if (!this.catTemplate.dob) {
        this.catTemplate.dob = '01/01/1970';
      }
      if (!this.catTemplate.name) {
        this.catTemplate.name = 'Cat ' + (this.data.items.length + 1);
      }
      if (!this.catTemplate.gender) {
        this.catTemplate.gender = 'other';
      }
      if (!this.catTemplate.tribe) {
        this.catTemplate.tribe = 'Unknown';
      }
      this.goTo('food');
    },
    editFood() {
      this.goTo('food');
    },

    getPrice: function () {
      this.goTo('price');
    },
    goTo: function (view) {
      if (view === 'sliders') {
        this.step.catDetail = true;
        this.step.food = false;
        this.step.price = false;
      }
      if (view === 'food') {
        this.step.catDetail = false;
        this.step.food = true;
        this.step.price = false;
        // Hack to get the scroller to work
        setTimeout(() => {
          const scroller = document.querySelector('#modal-overflow');

          scroller.scrollTop = 10;
        }, 500);
      }
      if (view === 'price') {
        this.step.catDetail = false;
        this.step.food = false;
        this.step.price = true;
        // Hack to get the scroller to work
        setTimeout(() => {
          const scroller = document.querySelector('#modal-overflow');

          scroller.scrollTop = 0;
        }, 500);
      }
    },
    updatePlan: function (id) {
      const self = this;

      http
        .put(`/me/purchases/${id}/update`, { session: this.newPlan.session })
        .then(() => {
          // this.$store.commit('update_subscription', response);
          self.$store.dispatch('getSubscription');
          // this.$ma.trackEvent({
          //   category: 'Click',
          //   action: 'Adjust Plan - Update Success',
          //   properties: {
          //     Pouches: this.newPlan.pouches + ' Pouches',
          //     'Dry Food': this.newPlan.dryfood / 1000 + ' Kg',
          //     Price: '£' + this.newPlan.displayCost,
          //     'Pouches Flavour': this.flavour.wetFoodFlavour,
          //     'Dry Food Flavour': this.flavour.dryFoodFlavour
          //   }
          // });
          http
            .put(`/me/purchases/${id}/update/details`, self.flavour)
            .then(() => {
              self.closeModal();
            });
        });
    },
    updateValues: function (val) {
      this.food.dryfood = val.dryValue;
      this.food.wetfood = val.wetValue;
    },
    setFlavour: function (val) {
      this.flavour.dryFoodFlavour = val.dryFood;
      this.flavour.wetFoodFlavour = val.wetFood;
    }
  },
  mounted() {
    this.initTippy();
  }
};
</script>

<style scoped>
.cat-card {
  max-width: 400px;

  width: auto;
}

.large-modal {
  min-width: 370px;
  width: auto;
}
</style>
